import * as React from "react"

import { CardList } from "@launchacademy/voyager"
import { graphql, Link } from "gatsby"
import _ from "lodash"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import "./css/strapi-category.css"
import buildImage from "../../../services/buildImage"

const CategoryShow = ({ data, location }) => {
  const articles = data.latestArticles.nodes.map((article) => {
    return {
      Image: buildImage(article.image?.localFile),
      header: article.articleTitle,
      articleUrl: `/blog/${article.slug}`,
      cardType: "article",
      body: article.description || article.contentPreview,
      categories: [
        {
          name: _.startCase(article.category.name.replace("-", " ")),
          url: `/blog/category/${article.category.slug}`,
        },
      ],
      timeToRead: Math.round(article.timeToRead.minutes),
    }
  })

  const category = {
    slug: data.category.slug,
    name: _.startCase(data.category.name),
  }

  return (
    <Layout>
      <Seo
        title={`${category.name} Articles`}
        pathname={location.pathname}
        description={
          data.category.description ||
          `${data.category.name} blog articles from Launch Academy, Boston's Leading Software Development Bootcamp`
        }
      />
      <ul className="breadcrumbs constrained-container">
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        <li>
          <Link to={`/blog/category/${category.slug}`}>{category.name}</Link>
        </li>
      </ul>
      <div className="constrained-container">
        <h1>{category.name} Articles</h1>
        <section className="card-list-container">
          <CardList content={articles} numberOfColumns={3} />
        </section>
      </div>
    </Layout>
  )
}

export default CategoryShow

export const pageQuery = graphql`
  query LatestCategoryArticles($slug: String!) {
    category: strapiCategory(slug: { eq: $slug }) {
      name
      description
      slug
    }
    latestArticles: allStrapiArticle(
      filter: { category: { slug: { eq: $slug } } }
      limit: 15
      sort: { order: DESC, fields: publishedAt }
    ) {
      nodes {
        articleTitle: title
        slug
        publishedAt
        description
        content {
          data {
            content
          }
        }
        internal {
          description
          ignoreType
          mediaType
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                width: 400
                height: 600
                webpOptions: {}
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
        category {
          name
          slug
        }
        timeToRead {
          minutes
        }
        contentPreview
      }
      totalCount
    }
  }
`
